<template>
	<div>
		<el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true"  class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="queryInfo.name" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="queryInfo.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
              <el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
              <el-button type="primary" @click="addUser()" icon="el-icon-plus" v-if="$store.state.userInfo.city_id.length<1">新增</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
			<!-- 代理商表单 -->
			<el-table :data="levelData" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="wx_id" label="ID"></el-table-column>
				<el-table-column prop="nickName" label="微信昵称"></el-table-column>
				<el-table-column label="微信头像">
					<template slot-scope="scope">
						<div>
<!--							<el-avatar shape="square" size="large" :src="scope.row.avatarUrl" v-if="scope.row.avatarUrl"></el-avatar>-->
<!--							<el-image shape="square" size="large" src="@/assets/img/default_headimg.jpg" v-else></el-image>-->
              <img class="header-img" :src="scope.row.avatarUrl" alt="" v-if="scope.row.avatarUrl">
              <img class="header-img" src="@/assets/img/default_headimg.jpg" alt="" v-else>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="phoneNumber" label="手机号"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="已关联角色" width="260px" v-if="$store.state.userInfo.city_id.length<1">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions style="margin-left: 5px;" v-if="scope.row.partner_id">城市合伙人</el-tag>
            <el-tag type="success" disable-transitions style="margin-left: 5px;" v-if="scope.row.agent_id">渠道推广员</el-tag>
            <el-tag type="danger" disable-transitions style="margin-left: 5px;" v-if="scope.row.jingli_id">经纪人</el-tag>
            <el-tag type="warning" disable-transitions style="margin-left: 5px;" v-if="scope.row.jishi_id">技师</el-tag>
            <el-tag type="info" disable-transitions style="margin-left: 5px;" v-if="scope.row.store_id">门店</el-tag>
          </template>
        </el-table-column>
				<el-table-column prop="createtime" label="加入时间"></el-table-column>
<!--				<el-table-column fixed="right" label="操作" width="240">-->
<!--					<template slot-scope="scope" v-if="scope.row.phoneNumber">-->
<!--						<el-button type="primary" v-if="scope.row.is_agent==0" plain size="small" @click="setAgent(scope.row)">设为代理人</el-button>-->
<!--						<el-button type="danger" v-else plain size="small" @click="goJump('/agent_add')">已为代理人</el-button>-->
<!--						<el-button type="primary" v-if="scope.row.is_jingli==0" plain size="small" @click="setJingli(scope.row)">设为经理人</el-button>-->
<!--						<el-button type="danger" v-else plain size="small" @click="goJump('/jingli_list')">已为经理人</el-button>-->
<!--					</template>-->
<!--				</el-table-column>-->
        <el-table-column label="操作" fixed="right" width="180" v-if="$store.state.userInfo.city_id.length<1">
          <template slot-scope="scope">
            <el-row>
              <el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
              <el-button type="warning" size="mini" @click="resetPwd(scope.row)">重置密码</el-button>
            </el-row>
          </template>
        </el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 设为代理人 -->
			<el-dialog title="设为代理人" width="60%" :visible.sync="addLevelDialog" :modal-append-to-body="false">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="120px" :rules="addlevelRules">
					<el-row>
					  	<el-col :span="12">
							<el-form-item label="真实名称" prop="real_name">
								<el-input v-model="addlevelForm.real_name" maxlength="10" placeholder="请输入真实姓名" :disabled="user_info.real_name?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="服务区域" prop="city_id" style="text-align: left;">
								<treeselect v-model="addlevelForm.city_id" :options="city_list" :normalizer="normalizer" :defaultExpandLevel="Infinity" :show-count="true" placeholder="请选择服务区域" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phoneNumber">
								<el-input v-model.number="addlevelForm.phoneNumber" placeholder="请输入手机号" disabled="disabled"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上级代理" prop="parent_id" style="text-align: left;">
								<el-select v-model="addlevelForm.parent_id" clearable filterable remote reserve-keyword placeholder="输入名称/手机号进行搜索"
										:remote-method="remoteMethod" :loading="loading">
								    <el-option
								      v-for="(item,key) in options"
								      :key="item.agent_id"
								      :label="item.real_name"
								      :value="item.agent_id">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号" prop="account">
								<el-input v-model="addlevelForm.account" maxlength="10" placeholder="请输入账号" :disabled="user_info.account?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="密码" prop="password">
								<el-input v-model="addlevelForm.password" placeholder="请输入密码" :disabled="user_info.password?'disabled':false" style="width:220px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="第二年分佣配比" prop="fypb_two" style="text-align: left;">
								<el-input v-model.number="addlevelForm.fypb_two" placeholder="请输入第二年分佣配比" style="width: 220px"></el-input>
								<span>%</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="第三年分佣配比" prop="fypb_three">
								<el-input v-model.number="addlevelForm.fypb_three" placeholder="请输入第三年分佣配比" style="width: 220px"></el-input>
								<span>%</span>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="addLevelDialog=false">取消</el-button>
							<el-button type="primary" @click="agentSubmit">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>
			
			<!-- 设为经理人 -->
			<el-dialog title="设为经理人" width="60%" :visible.sync="addJingliDialog" :modal-append-to-body="false">
				<el-form ref="addJingliForm" :model="addJingliForm" label-width="120px" :rules="addJingliRules">
					<el-row>
					  	<el-col :span="12">
							<el-form-item label="真实名称" prop="real_name">
								<el-input v-model="addJingliForm.real_name" maxlength="10" placeholder="请输入真实姓名" :disabled="user_info.real_name?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="服务区域" prop="city_id" style="text-align: left;">
								<treeselect v-model="addJingliForm.city_id" :options="city_list" :normalizer="normalizer" :defaultExpandLevel="Infinity" :show-count="true" placeholder="请选择服务区域" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号" prop="account">
								<el-input v-model="addJingliForm.account" maxlength="10" placeholder="请输入账号" :disabled="user_info.account?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="密码" prop="password">
								<el-input v-model="addJingliForm.password" placeholder="请输入密码" :disabled="user_info.password?'disabled':false" style="width:220px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phoneNumber">
								<el-input v-model.number="addJingliForm.phoneNumber" placeholder="请输入手机号" disabled="disabled"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="addJingliDialog=false">取消</el-button>
							<el-button type="primary" @click="jingliSubmit">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>

      <el-dialog :title="title" width="600px" top="5vh" :visible.sync="addVisible">
        <el-form ref="addRef" :model="addForm" :rules="addRules" label-width="150px" class="left">
          <el-row>
            <el-col :span="22">
              <el-form-item label="头像" prop="avatarUrl" style="text-align: left">
                <image-upload v-model="addForm.avatarUrl" :limit="1" :fileType="['png', 'jpg', 'jpeg']" :isShowTip="true" :fileSize="3" save-path="custom"></image-upload>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="昵称:" prop="nickName">
                <el-input v-model="addForm.nickName" placeholder="请输入昵称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="性别:" prop="gender">
                <el-radio v-model="addForm.gender" label="1">男</el-radio>
                <el-radio v-model="addForm.gender" label="2">女</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="账号:" prop="account">
                <el-input v-model="addForm.account" maxlength="10" placeholder="请输入账号" :disabled="addForm.admin_id?'disabled':false"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="密码:" prop="password">
                <el-input v-model="addForm.password" placeholder="请输入密码" :disabled="addForm.admin_id?'disabled':false"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="手机号:" prop="phoneNumber">
                <el-input v-model="addForm.phoneNumber" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <el-button type="default" @click="addClose">取消</el-button>
          <el-button type="primary" @click="addSub">保存</el-button>
        </div>
      </el-dialog>


		</el-card>
	</div>
</template>

<script>
	import WxUserSelect from "@/components/Form/WxUserSelect.vue";

  let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
  import ImageUpload from "@/components/Upload/ImageUpload.vue";
  import {addOrUpdate} from "@/api/request/custom";
	export default {
		components: {
      ImageUpload,
      WxUserSelect,
			Treeselect,Pagination
		},
		data() {
			return {
        title: "",
				queryInfo: {
					name: "",
					phone: "",
					pagenum: 1,
					pagesize: 10,
				},
				datePicker: "",
				options: [],
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					wx_id: 0,
					real_name: '',
					account: '',
					password: '',
					parent_id: '',
					fypb_two: '',
					fypb_three: '',
					city_id: null,
					phoneNumber: '',
				},
				addlevelRules: {
					real_name: [{
						required: true,
						message: "请输入真实名称",
						trigger: "blur"
					}, ],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择服务区域",
						trigger: "blur"
					}, ],
				},
				levelData: [],
				user_info: {},
				addJingliDialog: false,
				addJingliForm: {
					wx_id: 0,
					real_name: '',
					account: '',
					password: '',
					parent_id: '',
					city_id: null,
					phoneNumber: '',
					agent_type: 2,
				},
				addJingliRules: {
					real_name: [{
						required: true,
						message: "请输入真实名称",
						trigger: "blur"
					}, ],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择服务区域",
						trigger: "blur"
					}, ],
				},
				loading: false,
				city_list: [],
        addVisible: false,
        addForm: {
          wx_id: '',
          account: '',
          password: '',
          phoneNumber: '',
          nickName: '',
          avatarUrl: '',
          gender: 1
        },
        addRules: {
          avatarUrl: [{required: true, message: '请选择头像', trigger: 'blur'}],
          nickName: [{required: true, message: '请输入姓名', trigger: 'blur'}],
          gender: [
            {required: true, message: '请选择性别', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (value.length <= 0) return callback(new Error('请选择性别'));
                callback();
              }, trigger: 'blur'}],
          account: [{required: true, message: '请输入账号', trigger: 'blur'}],
          password: [
            { validator: (rule, value, callback) => {
                const pwd = value+""
                if (!this.addForm.wx_id) {
                  if (value.length <= 0) return callback(new Error('请输入密码'));
                }
                if (value) {
                  if (value.length < 6) return callback(new Error('请输入至少6位密码'));
                  if (value.length > 6) return callback(new Error('请输入最多16位密码'));
                }
                callback();
              }, trigger: 'blur'}],
          phoneNumber: [{required: true, message: '请输入手机号', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (!/^1[3-9][0-9]{9}$/.test(value)) {
                  return callback(new Error('请输入正确手机号'));
                }
                callback();
              }, trigger: 'blur'}],
        },
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getCityList();
				this.getUserList();
			}
		},
		methods: {
			getCityList(){
	  		var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
	  	},
			remoteMethod(query) {
				if (query !== '') {
					that.loading = true;
					setTimeout(() => {
            that.loading = false;
            var url = 'agent/parent_agent';
						let params = {
							agent_name: query,
							agent_id: that.addlevelForm.agent_id,
						};
						that.fd_post(url, params).then((res) => {
							if(res.status){
								that.options = res.list;
								console.log(res.list);
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 200);
				} else {
					this.options = [];
				}
			},
			getUserList() {
				let that = this;
				var url = 'custom/user_list';
				let params = {
					user_name: this.queryInfo.name,
					phone: this.queryInfo.phone,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.levelData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//转换菜单数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getUserList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getUserList();
			},
      addUser() {
        this.title = '新增用户';
        this.addForm = {
          wx_id: '',
          account: '',
          password: '',
          phoneNumber: '',
          nickName: '',
          avatarUrl: '',
          gender: '1'
        }
        this.addVisible = true;
      },
      update(item) {
        this.title = '编辑用户';
        this.addForm.wx_id = item.wx_id;
        this.addForm.account = item.account;
        this.addForm.password = "";
        this.addForm.phoneNumber = item.phoneNumber;
        this.addForm.nickName = item.nickName;
        this.addForm.avatarUrl = item.avatarUrl;
        this.addForm.gender = item.gender;
        this.addVisible = true;
      },
      resetPwd(item){
        this.$confirm('您确定要为'+item.nickName+ (item.account?'【'+item.account+'】':'')+'重置密码吗，确定后密码将会重置为【123456】？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var url = 'custom/resetPwd';
          this.fd_post(url, {wx_id: item.wx_id}).then((res) => {
            if(res.status) {
              this.$message.success('密码重置成功');
              this.getUserList();
            } else {
              this.$message.error(res.msg);
            }
          })
        }).catch(() => {
          this.$message.info('已取消重置')
        });
      },
      //查询
      search() {
        this.queryInfo.pagenum = 1;
        this.getUserList();
      },
      // 重置
      reset() {
        this.queryInfo.pagenum = 1;
        this.queryInfo.name = "";
        this.queryInfo.phone = "";
        this.getUserList();
      },
      //关闭添加弹窗
      addClose() {
        this.$refs.addRef.resetFields()
        this.addVisible = false
      },
      //确认添加
      async addSub() {
        // console.log("【提交表单】",this.addForm);return
        await this.$refs.addRef.validate(async value => {
          if (!value) return
          const res = await addOrUpdate(this.addForm).catch(error => {
            this.addVisible = false
            this.$message.error('网络错误');
          })
          this.addVisible = false
          if (res.code === 200) {
            this.$message.success('操作成功');
            this.getUserList();
          } else {
            this.$message.error(res.msg);
          }
        })
      },



			agentSubmit() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'agent/addOrUpdate';
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getUserList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						}, 1000);
					}
				});
			},
			jingliSubmit() {
				this.$refs.addJingliForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'agent/addOrUpdate';
							let params = that.addJingliForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addJingliDialog = false;
									that.$refs.addJingliForm.resetFields();
									that.$message.success('操作成功');
									that.getUserList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						}, 1000);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.el-row {
		text-align: left;
		margin-bottom: 20px;
	}
	.mr3{
	  margin-right: 5px;
	}
  .header-img{
    width:50px;
    height:50px;
    border-radius: 7px;
    overflow: hidden;
    display: block;
  }
</style>